import { useTranslation } from '@ubique-innovation/react-translations';
import { orderBy } from 'lodash-es';
import React, { useEffect, useState } from 'react';
import { useAuth } from 'react-oidc-context';
import { Company, CompanyResponse } from '../../types/Company';
import { SortTab, sortTabHeaderStyle } from '../../types/Tabs';
import SortArrow from '../templates/SortArrow';
import * as styles from './CompaniesOverviewTab.css';
import { loadCompanies } from '../../services/SuperDataService';

const CompaniesOverviewTab = (): React.ReactElement => {
    const auth = useAuth();
    const { t } = useTranslation();

    const [loading, setLoading] = useState(true);

    const [companies, setCompanies] = useState<Company[]>();

    const [activeSort, setActiveSort] = useState(SortTab.COMPANY_ID);
    const [sortAsc, setSortAsc] = useState(true);

    const sortBy = (sortTab: SortTab, sortByFunc: (e: Company) => string | number): void => {
        if (sortTab === activeSort) {
            const newSortAsc = !sortAsc;
            setCompanies(orderBy(companies, sortByFunc, newSortAsc ? 'asc' : 'desc'));
            setSortAsc(newSortAsc);
        } else {
            setCompanies(orderBy(companies, sortByFunc, 'asc'));
            setSortAsc(true);
            setActiveSort(sortTab);
        }
    };

    const sortByCompanyId = (): void => {
        sortBy(SortTab.COMPANY_ID, (e) => e.companyId);
    };

    const sortByCompanyName = (): void => {
        sortBy(SortTab.COMPANY_NAME, (e) => e.name);
    };

    useEffect(() => {
        setLoading(true);

        loadCompanies<CompanyResponse, Company[]>(auth, ({ companies: newCompanies }) => newCompanies).then(
            (newCompanies) => {
                setCompanies(orderBy(newCompanies, (e) => e.companyId));
                setLoading(false);
            },
        );
    }, [auth]);

    return (
        <div className={styles.wrapper}>
            {loading ? (
                ''
            ) : (
                <div className={styles.table}>
                    <header className={styles.header}>
                        <div className={styles.colHeader}>
                            <div
                                className={sortTabHeaderStyle(activeSort, SortTab.COMPANY_ID)}
                                role="button"
                                tabIndex={0}
                                onKeyDown={sortByCompanyId}
                                onClick={sortByCompanyId}
                            >
                                {t('companies.category.companyId')}{' '}
                            </div>
                            <SortArrow
                                activeSort={activeSort}
                                sortTab={SortTab.COMPANY_ID}
                                sort={sortByCompanyId}
                                asc={sortAsc}
                            />
                        </div>
                        <div className={styles.colHeader}>
                            <div
                                className={sortTabHeaderStyle(activeSort, SortTab.COMPANY_NAME)}
                                role="button"
                                tabIndex={0}
                                onKeyDown={sortByCompanyName}
                                onClick={sortByCompanyName}
                            >
                                {t('companies.name')}{' '}
                            </div>
                            <SortArrow
                                activeSort={activeSort}
                                sortTab={SortTab.COMPANY_NAME}
                                sort={sortByCompanyName}
                                asc={sortAsc}
                            />
                        </div>
                    </header>
                    {companies?.map((l) => (
                        <div className={styles.row} key={l.companyId}>
                            <div className={styles.col}>{l.companyId}</div>
                            <div className={styles.col}>{l.name}</div>
                        </div>
                    ))}
                </div>
            )}
        </div>
    );
};

export default CompaniesOverviewTab;
