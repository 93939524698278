import { AuthContextProps, AuthState } from 'react-oidc-context';

const API = process.env.REACT_APP_API_URL || '';
const API_SUPER_BASE = `${API}/v1/super`;

export const getAuthHeaders = (auth: AuthState): HeadersInit => ({
    Authorization: `Bearer ${auth.user?.access_token}`,
});

const loadSuper = <T, U>(auth: AuthContextProps, superPath: string, processData: (value: T) => U): Promise<U> =>
    fetch(`${API_SUPER_BASE}/${superPath}`, {
        method: 'GET',
        headers: getAuthHeaders(auth),
    })
        .then((response) => {
            if (response.ok) {
                return response.json();
            }
            if (response.status === 401 || response.status === 403) {
                auth.signoutRedirect();
            }
            return Promise.reject();
        })
        .then(processData);

export const loadWorkspaces = <T, U>(auth: AuthContextProps, processData: (value: T) => U): Promise<U> =>
    loadSuper(auth, 'workspaces', processData);

export const loadCompanies = <T, U>(auth: AuthContextProps, processData: (value: T) => U): Promise<U> =>
    loadSuper(auth, 'companies', processData);

export const loadCouponCreditCompanies = <T, U>(auth: AuthContextProps, processData: (value: T) => U): Promise<U> =>
    loadSuper(auth, 'coupons/overview', processData);
