import React, { useEffect, useState } from 'react';
import orderBy from 'lodash-es/orderBy';
import { useTranslation } from '@ubique-innovation/react-translations';
import { keyBy } from 'lodash-es';
import { useAuth } from 'react-oidc-context';
import { Company, CompanyResponse } from '../../types/Company';
import * as styles from './WorkspaceOverviewTab.css';
import { Workspace, WorkspaceResponse } from '../../types/Workspace';
import PublishToggle from '../templates/PublishToggle';
import SortArrow from '../templates/SortArrow';
import { SortTab, sortTabHeaderStyle } from '../../types/Tabs';
import { loadCouponCreditCompanies, loadWorkspaces } from '../../services/SuperDataService';

const WorkspaceOverviewTab = (): React.ReactElement => {
    const API = process.env.REACT_APP_API_URL || '';

    const auth = useAuth();
    const { t } = useTranslation();

    const [loading, setLoading] = useState(true);

    const [companies, setCompanies] = useState<{ [k in number]: Company }>();
    const [workspaces, setWorkspaces] = useState<Workspace[]>();

    const [activeSort, setActiveSort] = useState(SortTab.WORKSPACE_ID);
    const [sortAsc, setSortAsc] = useState(true);

    const sortBy = (sortTab: SortTab, sortByFunc: (e: Workspace) => string | number | boolean | undefined): void => {
        if (sortTab === activeSort) {
            const newSortAsc = !sortAsc;
            setWorkspaces(orderBy(workspaces, sortByFunc, newSortAsc ? 'asc' : 'desc'));
            setSortAsc(newSortAsc);
        } else {
            setWorkspaces(orderBy(workspaces, sortByFunc, 'asc'));
            setSortAsc(true);
            setActiveSort(sortTab);
        }
    };

    const sortByWorkspaceId = (): void => {
        sortBy(SortTab.WORKSPACE_ID, (e) => e.workspaceId);
    };

    const sortByWorkspaceCode = (): void => {
        sortBy(SortTab.WORKSPACE_CODE, (e) => e.workspaceCode);
    };

    const sortByWorkspaceName = (): void => {
        sortBy(SortTab.WORKSPACE_NAME, (e) => e.title);
    };

    const sortByPublished = (): void => {
        sortBy(SortTab.WORKSPACE_PUBLISHED, (e) => e.published);
    };

    const sortByRestricted = (): void => {
        sortBy(SortTab.WORKSPACE_RESTRICTED, (e) => e.restricted);
    };

    const sortByCompanyName = (): void => {
        sortBy(SortTab.COMPANY_NAME, (e) => companies?.[e.companyId].name);
    };

    useEffect(() => {
        setLoading(true);

        const companiesPromise = loadCouponCreditCompanies<CompanyResponse, Company[]>(
            auth,
            ({ companies: newCompanies }) => newCompanies,
        );
        const workspacesPromise = loadWorkspaces<WorkspaceResponse, Workspace[]>(
            auth,
            ({ workspaces: newWorkspaces }) => newWorkspaces,
        );

        Promise.all([companiesPromise, workspacesPromise]).then(([newCompanies, newWorkspaces]) => {
            setCompanies(keyBy(newCompanies, (e) => e.companyId));
            setWorkspaces(orderBy(newWorkspaces, (e) => e.workspaceId));
            setLoading(false);
        });
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [auth]);

    const publishSpace = async (workspaceId: number): Promise<void> => {
        await fetch(`${API}/v1/super/workspaces/${workspaceId}/publish`, {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
                Authorization: `Bearer ${auth.user?.access_token}`,
            },
        });
    };

    const unpublishSpace = async (workspaceId: number): Promise<void> => {
        await fetch(`${API}/v1/super/workspaces/${workspaceId}/unpublish`, {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
                Authorization: `Bearer ${auth.user?.access_token}`,
            },
        });
    };

    const handleOnChange = (id: number, p: boolean): void => {
        if (p) {
            unpublishSpace(id).then(() => {
                if (workspaces !== undefined) {
                    const l = [...workspaces];
                    l.filter((e) => e.workspaceId === id)[0].published = false;
                    setWorkspaces(l);
                }
            });
        } else {
            publishSpace(id).then(() => {
                if (workspaces !== undefined) {
                    const l = [...workspaces];
                    l.filter((e) => e.workspaceId === id)[0].published = true;
                    setWorkspaces(l);
                }
            });
        }
    };

    return (
        <div className={styles.wrapper}>
            {loading ? (
                ''
            ) : (
                <div className={styles.table}>
                    <header className={styles.header}>
                        <div className={styles.colHeader}>
                            <div
                                className={sortTabHeaderStyle(activeSort, SortTab.WORKSPACE_ID)}
                                role="button"
                                tabIndex={0}
                                onKeyDown={sortByWorkspaceId}
                                onClick={sortByWorkspaceId}
                            >
                                {t('workspaces.category.workspaceId')}
                            </div>
                            <SortArrow
                                activeSort={activeSort}
                                sortTab={SortTab.WORKSPACE_ID}
                                sort={sortByWorkspaceId}
                                asc={sortAsc}
                            />
                        </div>
                        <div className={styles.colHeader}>
                            <div
                                className={sortTabHeaderStyle(activeSort, SortTab.WORKSPACE_CODE)}
                                role="button"
                                tabIndex={0}
                                onKeyDown={sortByWorkspaceCode}
                                onClick={sortByWorkspaceCode}
                            >
                                {t('workspaces.category.code')}
                            </div>
                            <SortArrow
                                activeSort={activeSort}
                                sortTab={SortTab.WORKSPACE_CODE}
                                sort={sortByWorkspaceCode}
                                asc={sortAsc}
                            />
                        </div>
                        <div className={styles.colHeader}>
                            <div
                                className={sortTabHeaderStyle(activeSort, SortTab.WORKSPACE_NAME)}
                                role="button"
                                tabIndex={0}
                                onKeyDown={sortByWorkspaceName}
                                onClick={sortByWorkspaceName}
                            >
                                {t('workspaces.category.title')}
                            </div>
                            <SortArrow
                                activeSort={activeSort}
                                sortTab={SortTab.WORKSPACE_NAME}
                                sort={sortByWorkspaceName}
                                asc={sortAsc}
                            />
                        </div>
                        <div className={styles.colHeader}>
                            <div
                                className={sortTabHeaderStyle(activeSort, SortTab.WORKSPACE_PUBLISHED)}
                                role="button"
                                tabIndex={0}
                                onKeyDown={sortByPublished}
                                onClick={sortByPublished}
                            >
                                {t('workspaces.category.published')}
                            </div>
                            <SortArrow
                                activeSort={activeSort}
                                sortTab={SortTab.WORKSPACE_PUBLISHED}
                                sort={sortByPublished}
                                asc={sortAsc}
                            />
                        </div>
                        <div className={styles.colHeader}>
                            <div
                                className={sortTabHeaderStyle(activeSort, SortTab.WORKSPACE_RESTRICTED)}
                                role="button"
                                tabIndex={0}
                                onKeyDown={sortByRestricted}
                                onClick={sortByRestricted}
                            >
                                {t('super.workspaceoverview.title.restricted')}
                            </div>
                            <SortArrow
                                activeSort={activeSort}
                                sortTab={SortTab.WORKSPACE_RESTRICTED}
                                sort={sortByRestricted}
                                asc={sortAsc}
                            />
                        </div>
                        <div className={styles.colHeader}>
                            <div
                                className={sortTabHeaderStyle(activeSort, SortTab.COMPANY_NAME)}
                                role="button"
                                tabIndex={0}
                                onKeyDown={sortByCompanyName}
                                onClick={sortByCompanyName}
                            >
                                {t('workspaces.category.companyName')}
                            </div>
                            <SortArrow
                                activeSort={activeSort}
                                sortTab={SortTab.COMPANY_NAME}
                                sort={sortByCompanyName}
                                asc={sortAsc}
                            />
                        </div>
                    </header>
                    {workspaces?.map((l) => (
                        <div className={styles.row} key={l.workspaceId}>
                            <div className={styles.col}>{l.workspaceId}</div>
                            <div className={styles.col}>{l.workspaceCode}</div>
                            <div className={styles.col}>{l.title}</div>
                            <div className={styles.col}>
                                <PublishToggle
                                    id={l.workspaceCode}
                                    onChange={() => {
                                        handleOnChange(l.workspaceId, l.published);
                                    }}
                                    checked={l.published}
                                />
                            </div>
                            <div className={styles.col}>
                                {t(
                                    l.restricted
                                        ? 'super.workspaceoverview.restricted'
                                        : 'super.workspaceoverview.unrestricted',
                                )}
                            </div>
                            <div className={styles.col}>{companies?.[l.companyId].name}</div>
                        </div>
                    ))}
                </div>
            )}
        </div>
    );
};

export default WorkspaceOverviewTab;
