import React from 'react';
import includes from 'lodash-es/includes';
import { Navigate, Route, Routes } from 'react-router-dom';
import { useTranslation } from '@ubique-innovation/react-translations';
import { useAuth } from 'react-oidc-context';

import * as styles from './App.css';
import Coupons from './Coupons';
import Sidebar from './Sidebar';
import { Pathname } from '../types/Pathname';
import { themeClass } from './theme.css';
import Workspaces from './Workspaces';
import Companies from './Companies';
import AutoOnboarding from './AutoOnboarding';
import usePermissions from '../hooks/usePermissions';

// eslint-disable-next-line @typescript-eslint/no-non-null-assertion
export const ENV = process.env.REACT_APP_ENV! as string;

export const IDP_REDIRECT_PATH = ENV === 'PROD' ? '/flesk/signin-oidc' : '/signin-oidc';

const App = (): React.ReactElement => {
    const auth = useAuth();
    const { t } = useTranslation();
    const permissions = usePermissions();

    if (!auth.isAuthenticated && !auth.isLoading) {
        auth.signinRedirect();
    }

    return (
        <div className={`${styles.app} ${themeClass}`}>
            {includes(permissions, 'SUPER_ADMIN') ? (
                <>
                    <div className={styles.sidebar}>
                        <Sidebar />
                    </div>
                    <div className={styles.content}>
                        <Routes>
                            <Route path={Pathname.AUTO_ONBOARDING} element={<AutoOnboarding />} />
                            <Route path={Pathname.WORKSPACES} element={<Workspaces />} />
                            <Route path={Pathname.COMPANIES} element={<Companies />} />
                            <Route path={Pathname.COUPON} element={<Coupons />} />
                            <Route path="/" element={<Navigate to={Pathname.WORKSPACES} replace />} />
                            <Route path={IDP_REDIRECT_PATH} element={<Navigate to={Pathname.WORKSPACES} replace />} />
                        </Routes>
                    </div>
                </>
            ) : (
                <div className={styles.cont}>
                    <div className={styles.para}>{t('flesk.no.rights')}</div>
                    <button className={styles.btn} type="button" onClick={() => auth.signoutRedirect()}>
                        {t('navigation.logout')}
                    </button>
                </div>
            )}
        </div>
    );
};

export default App;
