import React, { useState } from 'react';
import { useTranslation } from '@ubique-innovation/react-translations';
import CompaniesTab from '../components/companies/CompaniesTab';
import CompaniesOverviewTab from '../components/companies/CompaniesOverviewTab';
import Header from './Header';

import * as styles from './Workspaces.css';

enum Company {
    ADD,
    OVERVIEW,
}
const Companies = (): React.ReactElement => {
    const [activeTab, setActiveTab] = useState(Company.ADD);
    const { t } = useTranslation();

    const tabs = [
        {
            title: t('companies.add.tab'),
            onClick: () => setActiveTab(Company.ADD),
            active: activeTab === Company.ADD,
        },
        {
            title: t('companies.overview'),
            onClick: () => setActiveTab(Company.OVERVIEW),
            active: activeTab === Company.OVERVIEW,
        },
    ];
    return (
        <>
            <Header headerTitle={t('navigation.companies')} tabs={tabs} />
            <div className={styles.formDiv}>
                {activeTab === Company.ADD && <CompaniesTab />}
                {activeTab === Company.OVERVIEW && <CompaniesOverviewTab />}
            </div>
        </>
    );
};

export default Companies;
