import { useTranslation } from '@ubique-innovation/react-translations';
import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { useAuth } from 'react-oidc-context';

import { filter, includes, map } from 'lodash-es';
import { CompanyType, ContactBexioIdNrName } from '../../types/Company';
import FormControls from '../templates/FormControls';
import SectionTitle from '../templates/SectionTitle';
import TextInput from '../templates/TextInput';
import * as styles from './CompaniesTab.css';
import Select, { SelectOption } from '../templates/Select';

const CompaniesTab = (): React.ReactElement => {
    const { t } = useTranslation();
    const auth = useAuth();
    const API = process.env.REACT_APP_API_URL || '';
    const [valid, setValid] = useState(true);
    const [loading, setLoading] = useState(false);
    const [formState, setFormState] = useState<CompanyType>({ name: '' });
    const [companyBexioSelectOptions, setCompanyBexioSelectOptions] = useState<SelectOption[]>([]);
    const [bexioCompanyText, setBexioCompanyText] = useState('');
    const [bexioCompanyOption, setBexioCompanyOption] = useState<SelectOption[]>();

    const requestHeaders = useMemo(
        () => ({
            'Content-Type': 'application/json',
            Authorization: `Bearer ${auth.user?.access_token}`,
        }),
        [auth.user?.access_token],
    );

    const onSave = useCallback(
        (company: CompanyType) => {
            setLoading(true);

            let urlSearchParams = new URLSearchParams({ name: company.name });
            if (company.bexioExternalId) {
                urlSearchParams = new URLSearchParams({
                    name: company.name,
                    bexioExternalId: company.bexioExternalId.toString(),
                });
            }
            fetch(`${API}/v1/super/companies/create?${urlSearchParams}`, { method: 'POST', headers: requestHeaders })
                .then((response) => {
                    if (response.ok) {
                        setLoading(false);
                        setFormState({ name: '' });
                        return response.json();
                    }
                    if (response.status === 401 || response.status === 403) {
                        auth.signoutRedirect();
                    }
                    return Promise.reject();
                })
                .catch((error) => console.error(error));
        },
        [API, auth, requestHeaders],
    );

    const handleSubmit = (): void => {
        if (!valid) {
            alert(t('messages.error.invalidForm'));
            return;
        }
        onSave(formState);
    };

    const validateForm = (f: CompanyType): boolean => f.name !== '';

    useEffect(() => {
        setValid(validateForm(formState));
    }, [formState]);

    const setName = useCallback(
        (e: React.ChangeEvent<HTMLInputElement>) => {
            const w = { ...formState };
            w.name = e.target.value;
            setFormState(w);
        },
        [formState],
    );
    useCallback(
        (e: React.ChangeEvent<HTMLInputElement>) => {
            const w = { ...formState };
            w.bexioExternalId = parseInt(e.target.value, 10);
            setFormState(w);
        },
        [formState],
    );

    useEffect(() => {
        fetch(`${API}/v1/super/companies/bexio_ids`, { headers: requestHeaders })
            .then<ContactBexioIdNrName[]>((response) => response.json())
            .then((cbis) => {
                setCompanyBexioSelectOptions(
                    map(cbis, (cbi) => ({
                        value: `${cbi.bexioNr}: ${cbi.bexioCompanyName}`,
                        id: cbi.bexioExternalId,
                    })),
                );
            });
    }, [API, requestHeaders]);

    const getBexioCompanyString = (id?: number): string => {
        if (id) {
            const cbi = filter(companyBexioSelectOptions, (cbso) => cbso.id === id)[0];
            setBexioCompanyText(cbi.value);
            return cbi.value;
        }
        return '';
    };

    return (
        <>
            <div className={styles.wrapper}>
                {loading ? (
                    ''
                ) : (
                    <form id="companies" className={styles.inputForm} onSubmit={handleSubmit}>
                        <div className={styles.companyTitle}>{t('companies.add.title')}</div>
                        <SectionTitle title={t('companies.name')} required />
                        <TextInput value={formState.name} onChange={setName} />
                        <SectionTitle title={t('companies.bexioContact')} />
                        <Select
                            options={bexioCompanyOption || companyBexioSelectOptions}
                            value={bexioCompanyText}
                            onChange={(v: number) => {
                                const id = { ...formState };
                                id.bexioExternalId = v;
                                setFormState(id);
                                getBexioCompanyString(v);
                            }}
                            inputChange={(e: React.ChangeEvent<HTMLInputElement>) => {
                                let val = e.target.value;
                                if (e.target.value.slice(0, -1) === getBexioCompanyString(formState.bexioExternalId)) {
                                    val = e.target.value.slice(-1);
                                }
                                setBexioCompanyText(val);
                                const filtered = companyBexioSelectOptions.filter((l) => {
                                    const valLow = val.toLowerCase();
                                    const low = l.value.toLowerCase();
                                    return includes(low, valLow);
                                });
                                setBexioCompanyOption(filtered);
                            }}
                        />
                    </form>
                )}
            </div>
            <FormControls id="companies" formValid={valid} />
        </>
    );
};

export default CompaniesTab;
